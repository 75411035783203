<template lang="html">
  <main>
    <HeaderWhite ref="headerwhite" />
    <b-container id="main-container">
      <!-- Modale per generazione PDF -->
      <!-- <b-modal
        id="modalpdf"
        hide-footer
        @hidden="clear_formpdf"
        @close="clear_formpdf"
        @hide="clear_formpdf"
      >
        <el-form :rules="rulesPdf" ref="formpdf" :model="formpdf">
          <span class="asterisk">
            I campi seguiti da <code>*</code> sono obbligatori
          </span>
          <FormInput
            label="Filtro per Provincia"
            name="province"
            :model="formpdf.province"
            v-model="formpdf.province"
            :options="provinces"
            :multiselect="true"
          />
          <FormInput
            label="Filtro Stato"
            name="status"
            :model="formpdf.status"
            v-model="formpdf.status"
            :options="requests_status"
            :multiselect="true"
          />
          <b-button type="button" variant="primary" @click="onSubmitPdf"
            >Visualizza PDF</b-button
          >
        </el-form>
      </b-modal> -->

      <!-- Sidebar e Corpo principale -->
      <div>
        <AdminSidebar nav="interventions" />
        <aside class="_body">
          <b-nav id="header">
            <div class="d-flex">
              <b-button variant="primary" :href="'/atb-interventions/new'">
                Nuovo Intervento
              </b-button>
              <b-button
                v-b-modal.modalpdf
                variant="primary"
                class="ml-2"
                @click="onSubmitPdf"
              >
                Genera PDF
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="handleResetFilters"
                class="ml-2"
              >
                Cancella filtri
              </b-button>
            </div>
          </b-nav>

          <!-- Form dei Filtri -->
          <b-row class="align-items-center mb-2">
            <b-col>
              <b-button
                v-b-toggle.collapse-filtri
                variant="link"
                class="clickable-header-filtri p-0 text-decoration-none"
              >
                Filtra Risultati
                <b-icon icon="chevron-down" class="when-open"></b-icon>
                <b-icon icon="chevron-up" class="when-closed"></b-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-collapse id="collapse-filtri">
            <el-form class="filter-form">
              <b-row class="filter-row">
                <b-col class="filter-col" lg="3" sm="12">
                  <FormInput
                    label="ID Intervento"
                    name="id"
                    placeholder="Inserisci ID Intervento"
                    :model="filters.id"
                    v-model="filters.id"
                    :options="idOptions"
                    :clearable="true"
                  />
                </b-col>
                <b-col class="filter-col" lg="3" sm="12">
                  <FormInput
                    label="Autobotte"
                    name="tanker"
                    :model="filters.tanker"
                    v-model="filters.tanker"
                    :options="tankers"
                    :clearable="true"
                  />
                </b-col>
                <b-col class="filter-col" lg="3" sm="12">
                  <FormInput
                    label="Dipendente"
                    name="employees"
                    :model="filters.employees"
                    v-model="filters.employees"
                    :options="employees"
                    :multiselect="true"
                    :clearable="true"
                  />
                </b-col>
              </b-row>

              <b-row class="filter-row">
                <b-col class="filter-col" lg="4" sm="12">
                  <FormInput
                    label="Stato"
                    name="status"
                    :model="filters.status"
                    v-model="filters.status"
                    :options="requests_status"
                    :clearable="true"
                  />
                </b-col>
                <b-col class="filter-col" lg="4" sm="12">
                  <FormInput
                    placeholder="Seleziona una data"
                    label="Data Inizio Intervento"
                    name="intervention_date"
                    :model="filters.intervention_date"
                    v-model="filters.intervention_date"
                    :datepicker="true"
                    :clearable="true"
                  />
                </b-col>
                <b-col class="filter-col" lg="4" sm="12">
                  <FormInput
                    placeholder="Seleziona una data"
                    label="Data Fine Intervento"
                    name="intervention_ending_date"
                    :model="filters.intervention_ending_date"
                    v-model="filters.intervention_ending_date"
                    :datepicker="true"
                    :clearable="true"
                  />
                </b-col>
              </b-row>

              <b-row class="filter-row">
                <b-col class="filter-col" lg="4" sm="12">
                  <el-form-item label="Numero Fattura" prop="invoice_number">
                    <el-input
                      placeholder="N.36234872"
                      :model="filters.invoice_number"
                      v-model="filters.invoice_number"
                      type="text"
                      :clearable="true"
                    />
                  </el-form-item>
                </b-col>
                <b-col class="filter-col" lg="4" sm="12">
                  <el-form-item prop="min_amount_paid">
                    <label :style="{ color: '#606266' }">
                      Minimo importo pagato
                    </label>
                    <b-form-input
                      id="range-1"
                      :model="filters.min_amount_paid"
                      v-model="filters.min_amount_paid"
                      value="0"
                      min="0"
                    ></b-form-input>
                  </el-form-item>
                </b-col>
                <b-col class="filter-col" lg="4" sm="12">
                  <el-form-item prop="max_amount_paid">
                    <label :style="{ color: '#606266' }">
                      Massimo importo pagato
                    </label>
                    <b-form-input
                      id="range-2"
                      :model="filters.max_amount_paid"
                      v-model="filters.max_amount_paid"
                      value="0"
                      min="0"
                    ></b-form-input>
                  </el-form-item>
                </b-col>
              </b-row>

              <b-row class="filter-row">
                <b-col>
                  <FormInput
                    label="Descrizione"
                    name="description"
                    :model="filters.description"
                    v-model="filters.description"
                    :clearable="true"
                    :text="true"
                    rows="3"
                    max-rows="8"
                  />
                </b-col>
              </b-row>
            </el-form>
          </b-collapse>
          <!-- Tabella degli Interventi -->
          <b-table
            id="my-table"
            responsive
            hover
            :items="interventions"
            :fields="fields"
            sort-by="id"
            :sort-desc="true"
            :sort-compare="sortCompare"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(id)="data">
              <span>{{ data.item.id }}</span>
            </template>
            <template #cell(intervention_date)="data">
              <span>{{ formatDate(data.item.intervention_date) }}</span>
            </template>
            <template #cell(intervention_ending_date)="data">
              <span>{{ formatDate(data.item.intervention_ending_date) }}</span>
            </template>
            <<template #cell(status)="data">
              <el-select
                style="width: 100px !important"
                :value="data.item.status"
                @change="onStatusChange(data.item, $event)"
              >
                <el-option
                  v-for="option in requests_status"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                />
              </el-select>
            </template>

            <template #cell(employees)="data">
              <span>{{ data.item.employees.join(", ") }}</span>
            </template>
            <template #cell(invoice_number)="data">
              <span>{{ data.item.invoice_number }}</span>
            </template>
            <template #cell(amount_paid)="data">
              <span>
                {{ data.item.amount_paid }}
                {{ data.item.amount_paid ? "€" : "" }}
              </span>
            </template>
            <template #cell(details)="row">
              <!-- Pulsante "Gestisci" per reindirizzare alla pagina di gestione -->
              <b-button
                size="sm"
                variant="primary"
                :href="'/atb-interventions/' + row.item.id + '/edit'"
              >
                Gestisci
              </b-button>
              <!-- Pulsante per mostrare/nascondere i dettagli -->
              <b-button
                size="sm"
                variant="info"
                @click="row.toggleDetails"
                class="ml-2"
              >
                {{ row.detailsShowing ? "Nascondi" : "Mostra" }} dettagli
              </b-button>
            </template>

            <!-- Dettagli della riga -->
            <template #row-details="row">
              <b-card id="details">
                <b-row>
                  <b-col cols="12" lg="4">
                    <b>ID Intervento:</b>
                    <span>{{ row.item.id }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>ID Autobotte:</b>
                    <span>{{ row.item.tanker }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>Targa Autobotte:</b>
                    <span>{{ row.item.plate }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>ID Dipendenti:</b>
                    <span>{{ row.item.employees.join(", ") }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>Descrizione:</b>
                    <span>{{ row.item.description }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Data Inizio Intervento:</b>
                    <span>{{ row.item.intervention_date }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Data Fine Intervento:</b>
                    <span>{{ row.item.intervention_ending_date }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Stato:</b>
                    <span>{{
                      row.item.status == "open" ? "Aperto" : "Chiuso"
                    }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Numero Fattura:</b>
                    <span>{{ row.item.invoice_number }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Importo:</b>
                    <span>
                      {{ row.item.amount_paid }}
                      {{ row.item.amount_paid ? "€" : "" }}
                    </span>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>

          <!-- Paginazione -->
          <div id="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>
        </aside>
      </div>
    </b-container>
  </main>
</template>

<script>
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";
import HeaderWhite from "@/components/header-white.vue";

export default {
  name: "Interventions",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput,
  },
  data() {
    return {
      // Lista degli interventi
      interventions: [],
      // Filtri per la ricerca
      filters: {
        // id: "",
        // min_amount_paid: 0,
        // max_amount_paid: 0,
        // tanker: "",
        // employee: "",
        // status: "",
        // intervention_date: "",
        // intervention_ending_date: "",
        // invoice_number: "",
        // min_amount_paid: "",
        // max_amount_paid: "",
        // description: "",
      },
      // filters: { min_amount_paid: 0, max_amount_paid: 0 },
      // Opzioni per i select dei filtri
      tankers: [], // Popolati via fetch
      employees: [], // Popolati via fetch
      requests_status: [
        { value: "open", text: "Aperto" },
        { value: "closed", text: "Chiuso" },
      ],
      // Campi della tabella
      fields: [
        { key: "show_details", label: "" },
        { key: "id", label: "ID", sortable: true },
        { key: "employees", label: "ID Dipendente" },
        { key: "intervention_date", label: "Data Inizio", sortable: true },
        { key: "intervention_ending_date", label: "Data Fine", sortable: true },
        { key: "invoice_number", label: "N. Fattura", sortable: false },
        { key: "amount_paid", label: "Importo", sortable: false },
        { key: "status", label: "Stato" },
        { key: "details", label: "", sortable: false },
      ],
      // Parametri di paginazione
      currentPage: 1,
      perPage: 10,
      count: 0,
      access_token: "",
      // Dati per la generazione PDF
      // formpdf: {
      //   province: [],
      //   status: [],
      // },
      // rulesPdf: {
      //   province: [
      //     { required: true, message: "Seleziona almeno una provincia" },
      //   ],
      //   status: [{ required: true, message: "Seleziona almeno uno stato" }],
      // },
      // provinces: [], // Popolati via fetch o file JSON
    };
  },
  computed: {
    idOptions() {
      return this.interventions.map((item) => ({
        text: item.id.toString(),
        value: item.id,
      }));
    },
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true,
    },
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    // Inizializza i filtri dalla query string, se presenti
    const query = this.$route.query;
    if (query.id) this.filters.id = query.id;
    if (query.tanker) this.filters.tanker = query.tanker;
    if (query.employees) this.filters.employees = query.employees;
    if (query.status) this.filters.status = query.status;
    if (query.intervention_date)
      this.filters.intervention_date = query.intervention_date;
    if (query.intervention_ending_date)
      this.filters.intervention_ending_date = query.intervention_ending_date;
    if (query.invoice_number)
      this.filters.invoice_number = query.invoice_number;
    if (query.min_amount_paid)
      this.filters.min_amount_paid = query.min_amount_paid;
    if (query.max_amount_paid)
      this.filters.max_amount_paid = query.max_amount_paid;
    if (query.description) this.filters.description = query.description;

    // Carica gli interventi
    this.getData(!!Object.keys(this.filters).length);

    // Esempio di fetch per popolare tankers
    fetch(`${process.env.VUE_APP_BACKEND_URL}/tankers/`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tankers = data.results.map((tanker) => ({
          text: `${tanker.atbcustomer.corporate_name}`,
          value: tanker.id,
        }));
      });

    // Esempio di fetch per popolare employees
    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        this.employees = data.results.map((employee) => ({
          text: employee.name,
          value: employee.id,
        }));
      });

    // Carica le province (da file JSON o API)
    fetch("/provinces.json")
      .then((res) => res.json())
      .then((data) => {
        this.provinces = data.map((i) => ({ text: i.text, value: i.value }));
      });
  },
  methods: {
    filterBy() {
      // Formatta le date se presenti
      if (this.filters.intervention_date) {
        this.filters.intervention_date = this.$moment(
          this.filters.intervention_date
        ).format("YYYY-MM-DD");
      } else {
        delete this.filters.intervention_date;
      }
      if (this.filters.intervention_ending_date) {
        this.filters.intervention_ending_date = this.$moment(
          this.filters.intervention_ending_date
        ).format("YYYY-MM-DD");
      } else {
        delete this.filters.intervention_ending_date;
      }
      // Rimuove i campi vuoti
      Object.keys(this.filters).forEach((key) => {
        if (!this.filters[key]) delete this.filters[key];
      });
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        body = JSON.stringify(this.filters);
      }
      route += "?limit=1000";
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
        body,
      }).then(async (response) => {
        const data = await response.json();
        this.interventions = data.results;
        this.count = data.count;
      });
    },
    onStatusChange(item, newStatus) {
      // mostriamo un toast informativo;
      // la gestione vera dell'intervento avviene dalla pagina "Gestisci"
      this.$bvToast.toast(
        "Per modificare, utilizza la pagina di gestione, cliccando il pulsante 'GESTISCI'",
        {
          title: "Info",
          variant: "info",
          solid: true,
        }
      );
    },
    formatDate(date) {
      return date ? this.$moment(date).format("DD/MM/YYYY") : "";
    },
    sortCompare(a, b, key) {
      if (key === "intervention_date" || key === "intervention_ending_date") {
        return this.$moment(a[key]).unix() - this.$moment(b[key]).unix();
      }
      return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
    },
    handleResetFilters() {
      this.filters = {
        id: "",
        tanker: "",
        employees: "",
        status: "",
        intervention_date: "",
        intervention_ending_date: "",
        invoice_number: "",
        min_amount_paid: "",
        max_amount_paid: "",
        description: "",
      };
      this.$bvToast.toast("Filtri cancellati", {
        title: "Successo",
        variant: "success",
        solid: true,
      });
    },
    // clear_formpdf() {
    //   this.formpdf = { province: [], status: [] };
    // },
    onSubmitPdf() {
      // this.$refs.formpdf.validate(async (valid) => {
      //   if (valid) {
      // let route = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/pdf/`;

      // Costruisci il payload filtrando solo i campi con valori "veri"
      const payload = {};
      for (const key in this.filters) {
        // Controlla se il valore esiste ed è diverso da una stringa vuota
        if (
          this.filters[key] !== undefined &&
          this.filters[key] !== null &&
          this.filters[key] !== ""
        ) {
          // Per il campo "employees", assicurati che sia un array
          if (key === "employees") {
            // Se non è un array, lo converte in array; altrimenti lo lascia com'è
            payload[key] = Array.isArray(this.filters[key])
              ? this.filters[key]
              : [this.filters[key]];
          } else {
            payload[key] = this.filters[key];
          }
        }
      }

      // /* $1 */

      const route = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/pdf/`;
      fetch(route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (r) => {
          const filename = `interventions_${new Date().toLocaleDateString()}.pdf`;
          const blob = await r.blob();
          const newBlob = new Blob([blob], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            const objUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.download = filename;
            link.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
            this.$bvModal.hide("modalpdf");
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore generazione PDF",
            variant: "danger",
            solid: true,
          });
        });
    },
    // });
    // },
  },
};
</script>

<style scoped>
.filter-col {
  margin-top: 10px;
}
.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
