<template lang="pug">
  el-form-item(:label="label" :prop="name")
    el-date-picker(
      v-if="datepicker && timepicker" @input="handleDateChange"
      :value="model" format="yyyy-MM-ddTHH:mm"
    )
    el-date-picker(
      v-else-if="datepicker" locale="it-IT" @input="handleDateChange"
      :value="model" :options="options" format="dd/MM/yyyy"
    )
    el-time-select(
      v-else-if="timepicker" locale="it-IT" @input="handleDateChange"
      :value="model" :options="options" format="HH:mm:ss" value-format="HH:mm:ss"
    )
    el-select(
      v-else-if="options" filterable @input="handleChange"
      :aria-describedby="'input'+name+'feedback'" :value="model"
      :multiple="multiselect" :clearable="clearable"
    )
      el-option(
        v-for="item in options"
        :key="item.value" :label="item.text" :value="item.value"
      )
    textarea.el-textarea__inner(
      v-else-if="text" @input="handleChange"
      :value="model" :rows="3" :max-rows="6" type="textarea"
    )
    el-input-number(
      v-else-if="number" @input="handleChange" :value="model"
      controls-position="right" :min="1"
    )
    input.el-input__inner(
      v-else @input="handleChange" :value="model"
    )
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "FormInput",
  props: [
    "label",
    "model",
    "name",
    "text",
    "options",
    "multiselect",
    "datepicker",
    "timepicker",
    "number",
    "clearable",
  ],
  data() {
    return {};
  },
  methods: {
    handleDateChange(value) {
      this.$emit("input", value);
    },
    handleChange(event) {
      if (event.target) this.$emit("input", event.target.value);
      else this.$emit("input", event);
    },
  },
};
</script>
