<template lang="pug">
  main
    HeaderWhite(ref="headerwhite")
    b-container#main-container
      b-modal(
        id="modalformex" @hidden="formex={}" hide-footer
      )
        h2 Aggiungi un nuovo foglio d'esercizio
        el-form(
          :rules="rulesex" label-width="90px" ref="formex" :model="formex"
        )
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Data"
            :model="formex.date" :datepicker="true"
            v-model="formex.date" name="date"
          )
          el-form-item(label="Tipo foglio")
            el-select(
              placeholder="Seleziona tipo"
              v-model="formex.sheet_type"
              :model="formex.sheet_type"
              :clearable="true"
            )
              el-option(label="Intervento ATB", value="atb")
              el-option(label="Richiesta d'intervento", value="req")
          FormInput(
            label="Dipendenti" name="employees"
            :model="formex.employees" v-model="formex.employees"
            :options="employees" :multiselect="true"
          )
          //- FormInput(
          //-   label="Veicoli" name="vehicles"
          //-   :model="formex.vehicles" v-model="formex.vehicles"
          //-   :options="vehicles" :multiselect="true"
          //- )
          FormInput(
            label="Autobotti" name="tankers"
            :model="formex.tankers" v-model="formex.tankers"
            :options="tankers" :multiselect="true"
          )
          FormInput(
            label="Note" :text="true"
            :model="formex.note"
            v-model="formex.note" name="note"
          )
          b-button(variant="primary" @click="onSubmitEx") Salva

      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rulesop" ref="formop" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          b-row
            b-col(md="10" sm="12")
              FormInput(
                label="Foglio d'esercizio" name="fe"
                :model="form.fe" v-model="form.fe"
                :options="exerciseSheets"
              )
            b-col(md="2" sm="12")
              b-button.add-b-b(variant="success" block v-b-modal.modalformex)
                b-icon-plus

          FormInput(
            label="Orario" :model="form.hour" :timepicker="true"
            v-model="form.hour" name="hour"
          )
          FormInput(
            label="Note" :text="true"
            :model="form.note" v-model="form.note" name="note"
          )
          b-button(
            type="button" variant="primary" @click="createIntervention"
          ) Salva
      //- Sezione principale: Form per i dati generali dell'intervento
      div
        AdminSidebar(nav="interventions")
        aside._body
          h3 Informazioni intervento autobotte
          el-form(:rules="rules" ref="intervention" :model="intervention")
            span.asterisk I campi contrassegnati da 
              <code>*</code> sono obbligatori
            hr
            b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Nome azienda" 
                  name="tanker" 
                  :model="intervention.tanker" 
                  v-model="intervention.tanker" 
                  :options="tankers" 
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Targa" 
                  name="plate" 
                  :model="intervention.plate" 
                  v-model="intervention.plate" 
                  :options="plates" 
                  :clearable="true"
                )
            b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Data Inizio Intervento" 
                  name="intervention_date" 
                  :model="intervention.intervention_date" 
                  v-model="intervention.intervention_date" 
                  :datepicker="true" 
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Data Fine Intervento" 
                  name="intervention_ending_date" 
                  :model="intervention.intervention_ending_date" 
                  v-model="intervention.intervention_ending_date" 
                  :datepicker="true" 
                  :clearable="true"
                )
                b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Stato"
                  name="status"
                  :model="intervention.status"
                  v-model="intervention.status"
                  :options="requests_status"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Dipendenti"
                  name="employees"
                  :model="intervention.employees"
                  v-model="intervention.employees"
                  :options="employees"
                  :multiselect="true"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="N. Fattura"
                  placeholder="N.36234872"
                  :model="intervention.invoice_number"
                  v-model="intervention.invoice_number"
                  type="text"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Importo pagato"
                  :model="intervention.amount_paid"
                  v-model="intervention.amount_paid"
                  type="number"
                  :clearable="true"
                )
            b-row
              b-col(cols="12")
                FormInput(
                  label="Descrizione"
                  name="description"
                  :model="intervention.description"
                  v-model="intervention.description"
                  :text="true"
                  rows="3"
                  max-rows="6"
                  :clearable="true"
                )
            hr
            h4 Storico interventi
            b-nav#header
              b-button(variant="primary" v-b-modal.modalform) Nuovo intervento
              #right
                b-input(placeholder="Filtra" v-model="filter")
            b-table(
              :items="interventions" :fields="fields" :filter="filter" 
              hover responsive 
              class="mt-2"
            )
              template(#cell(date)="data")
                span {{ formatDate(data.item.date+' '+data.item.hour) }}
              template(#cell(employees)="data")
                span {{ data.item.employees.map(e => e.name).join(', ') }}
              template(#cell(vehicles)="data")
                span {{ data.item.vehicles.map(v => v.license_plate).join(', ') }}
              template(#cell(note)="data")
                span {{ data.item.note }}
              template(#cell(actions)="row")
                b-button(
                  size="sm" class="mr-2" variant="light" v-b-modal.modalform
                  @click="handleEdit(row.item, row.index)"
                )
                  b-icon-pencil
                b-button(
                  size="sm" variant="danger"
                  @click="handleDelete(row.index)"
                )
                  b-icon-trash

            b-button(
              @click="onSubmit()" variant="primary"
              style="display: block;margin: 50px auto;"
            ) Salva intervento
            b-button(
              @click="goBack"
              style="margin: 50px auto;"
            ) Torna indietro
</template>

<script>
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";
import HeaderWhite from "@/components/header-white.vue";

export default {
  name: "InterventionsNew",
  props: ["id"],
  components: { HeaderWhite, AdminSidebar, FormInput },
  data() {
    return {
      counter: 0,
      intervention: {},
      interventions: [],
      formex: {},
      exerciseSheets: [], // Storico creato localmente prima di salvare
      form: {},
      temp_form: {
        index: null,
        data: {},
      },
      filter: "", // Filtro per la tabella
      // Opzioni per select e tabelle
      tankers: [],
      plates: [],
      employees: [],
      vehicles: [],
      requests_status: [
        { value: "open", text: "Aperto" },
        { value: "closed", text: "Chiuso" },
      ],
      fields: [
        { key: "date", label: "Data" },
        { key: "fe.id", label: "FE" },
        { key: "employees", label: "Dipendenti" },
        { key: "vehicles", label: "Veicoli" },
        { key: "note", label: "Note" },
        { key: "actions", label: "" },
      ],
      rules: {
        tanker: [{ required: true, message: "Autobotte obbligatoria" }],
        plate: [{ required: true, message: "Targa obbligatoria" }],
        intervention_date: [
          { required: true, message: "Data inizio obbligatoria" },
        ],
        intervention_ending_date: [
          { required: true, message: "Data fine obbligatoria" },
        ],
        status: [{ required: true, message: "Stato obbligatorio" }],
        description: [{ required: true, message: "Descrizione obbligatoria" }],
      },
      rulesex: {
        date: [{ required: true, message: "La data è obbligatoria" }],
      },
      rulesop: {
        hour: [{ required: true, message: "L'orario è obbligatorio" }],
        fe: [
          { required: true, message: "Il foglio d'esercizio è obbligatorio" },
        ],
      },
    };
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    // Recupera i dati per tankers, plates, employees, vehicles, e opzioni per fogli di esercizio
    // Esempio di fetch per tankers:
    fetch(`${process.env.VUE_APP_BACKEND_URL}/tankers/`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        this.tankers = data.results.map((tanker) => ({
          text: tanker.atbcustomer.corporate_name,
          value: tanker.id,
        }));
        this.plates = data.results.map((tanker) => ({
          text: tanker.plate,
          value: tanker.plate,
        }));
      });

    // Analogamente per plates, employees, vehicles e per exerciseSheets (ad esempio, da /exercise-sheets/)
    fetch(`${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/?limit=1000`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    }).then(async (response) => {
      const sheets = await response.json();
      for (const sheet of sheets.results) {
        if (sheet.sheet_type === "atb") {
          this.exerciseSheets.push({
            text: `${sheet.id} - ${sheet.date}`,
            value: sheet.id,
          });
        }
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=100`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const vehicles = await response.json();
      for (const vehicle of vehicles.results) {
        this.vehicles.push({
          text: vehicle.license_plate,
          value: vehicle.id,
        });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/?limit=100`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const employees = await response.json();
      for (const employee of employees.results) {
        this.employees.push({
          text: employee.name,
          value: employee.id,
        });
      }
    });
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("YYYY/MM/DD") : "";
    },
    handleEdit(data, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
      this.form.employees = data.employees.map((x) => x.id);
      this.form.vehicles = data.vehicles.map((x) => x.id);
      this.form.fe = data.fe.id;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.operations, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {},
        };
      }
    },
    handleDelete(index) {
      this.interventions.splice(index, 1);
    },
    onSubmitEx() {
      this.$refs["formex"].validate((valid) => {
        if (valid) {
          let path = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/`;
          const action = {
            method: "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };

          // Se l'utente non ha impostato sheet_type, forziamo un valore di default
          if (!this.formex.sheet_type) {
            this.formex.sheet_type = "atb";
          }

          // Se non devi usare vehicles (perché sheet_type=atb), forza a []
          if (this.formex.sheet_type === "atb" && !this.formex.vehicles) {
            this.formex.vehicles = [];
          }

          // o semplicemente se non esiste:
          if (!this.formex.vehicles) {
            this.formex.vehicles = [];
          }

          this.formex.date = this.$moment(
            this.formex.date,
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ).format("YYYY-MM-DD");

          this.$refs.headerwhite.loading = true;
          // /* $1 */
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.formex),
          })
            .then(async (r) => {
              const st = await r.status;
              if (st == 201) {
                this.$bvToast.toast(
                  "Foglio d'esercizio inserito con successo",
                  {
                    title: "Modifica effettuata",
                    variant: "success",
                    solid: true,
                  }
                );
                const data = await r.json();
                // /* $1 */

                this.exerciseSheets.push({
                  text: `${data.id} - ${data.date}`,
                  value: data.id,
                });
                this.form.fe = data.id;
                this.$bvModal.hide("modalformex");
                this.formex = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Foglio d'esercizio non inserito",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Foglio d'esercizio non inserito",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    async createIntervention() {
      this.$refs["formop"].validate(async (valid) => {
        if (valid) {
          this.$refs.headerwhite.loading = true;
          // get data of exerciseSheets
          if (this.form.fe) {
            await fetch(
              `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${this.form.fe}/`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.access_token}`,
                },
              }
            ).then(async (res) => {
              this.form.fe = await res.json();
            });
          }

          this.form.id = this.counter++;
          if (this.form.fe.foreman) {
            this.form.employees = [
              this.form.fe.foreman,
              ...this.form.fe.employees,
            ];
          } else {
            this.form.employees = this.form.fe.employees;
          }
          this.form.vehicles = this.form.fe.vehicles;
          this.form.date = this.form.fe.date;

          if (this.temp_form.index != null) {
            this.$set(this.interventions, this.temp_form.index, this.form);
            this.temp_form = {
              index: null,
              data: {},
            };
          } else {
            this.interventions.push(this.form);
          }
          this.form = {};
          this.$bvModal.hide("modalform");
          this.$refs.headerwhite.loading = false;
        } else {
          return false;
        }
      });
    },
    onSubmit() {
      this.$refs["intervention"].validate((valid) => {
        if (this.intervention.intervention_date) {
          this.intervention.intervention_date = this.$moment(
            this.intervention.intervention_date
          ).format("YYYY-MM-DD");
        } else {
          delete this.intervention.intervention_date;
        }
        if (this.intervention.intervention_ending_date) {
          this.intervention.intervention_ending_date = this.$moment(
            this.intervention.intervention_ending_date
          ).format("YYYY-MM-DD");
        } else {
          delete this.intervention.intervention_ending_date;
        }
        if (valid) {
          let path = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/`;
          let action = {
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };
          this.$refs.headerwhite.loading = true;
          // /* $1 */

          // create the intervention request
          fetch(action.url, {
            method: "POST",
            headers: action.headers,
            body: JSON.stringify(this.intervention),
          })
            .then(async (r) => {
              const st = await r.status;
              if (st != 201) {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true,
                });
                return;
              }

              const data = await r.json();
              const intervention_id = data.id;
              path += intervention_id + "/interventions/";
              action.url = path;

              // create the interventions
              for (const int of this.interventions) {
                const data = { ...int };
                if (data.fe) data.fe = data.fe.id;
                data.employees = data.employees.map((x) => x.id);
                data.vehicles = data.vehicles.map((x) => x.id);

                const res = await fetch(action.url, {
                  method: "POST",
                  headers: action.headers,
                  body: JSON.stringify(data),
                });
                if (res.status != 201) {
                  this.$bvToast.toast("Intervento non creato", {
                    title: "Errore nel salvataggio",
                    variant: "danger",
                    solid: true,
                  });
                }
              }

              this.$bvToast.toast("Sarai presto reindirizzato alla pagina", {
                title: "Richiesta d'intervento creata",
                variant: "success",
                solid: true,
              });
              window.setTimeout(() => {
                window.location.href =
                  "/atb-interventions/" + intervention_id + "/edit";
              }, 1000);
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Errore nella creazione",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push("/atb-interventions");
    },
  },
};
</script>
