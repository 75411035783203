<template lang="pug">
  main
    HeaderWhite(ref="headerwhite")
    b-container#main-container
      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rules" label-width="90px" ref="form" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Data"
            :model="form.date" :datepicker="true"
            v-model="form.date" name="date"
          )
          el-form-item(v-if="!isEditing" label="Tipo foglio" prop="sheet_type")
            el-select(v-model="form.sheet_type" placeholder="Seleziona il tipo")
              el-option(:label="'Automezzi'", :value="'req'")
              el-option(:label="'Autobotti'", :value="'atb'")
          FormInput(
            label="Dipendenti" name="employees"
            :model="form.employees" v-model="form.employees"
            :options="employees" :multiselect="true" :clearable="true"
          )
          // Campo per Automezzi (vehicles) visibile se il tipo è "req"
          el-form-item(v-if="form.sheet_type === 'req'" label="Automezzi" prop="vehicles")
            FormInput(
              label="" name="vehicles"
              :model="form.vehicles" v-model="form.vehicles"
              :options="vehicles" :multiselect="true" :clearable="true"
            )

          // Campo per Autobotti (tankers) visibile se il tipo è "atb"
          el-form-item(v-else-if="form.sheet_type === 'atb'" label="Autobotti" prop="tankers")
            FormInput(
              label="" name="tankers"
              :model="form.tankers" v-model="form.tankers"
              :options="tankers" :multiselect="true" :clearable="true"
            )
          FormInput(
            label="Note" :text="true"
            :model="form.note"
            v-model="form.note" name="note"
          )
          b-button(type="button" variant="primary" @click="onSubmit") Salva
      div
        AdminSidebar(nav="exercisesheets")
        aside._body
          b-nav#header
            b-button(variant="primary" @click="handleNew")
              | Nuovo foglio d'esercizio
          el-form#filters
            h4 Filtra
            b-row
              b-col
                label ID
                el-input(
                  v-model="filters.id"
                  :clearable="true"
                )
              b-col
                label Data
                el-date-picker(
                  locale="it-IT" v-model="filters.date" format="dd/MM/yyyy"
                  :clearable="true"
                )
              b-col
                label Note
                el-input(
                  v-model="filters.note"
                  :clearable="true"
                )
              b-col
                label Dipendenti
                FormInput(
                  label="" name="employees"
                  :model="filters.employees" v-model="filters.employees"
                  :options="employees"
                  :clearable="true" :multiselect="true"
                )
              b-col
                label Veicoli
                FormInput(
                  label="" name="vehicles"
                  :model="filters.vehicles" v-model="filters.vehicles"
                  :options="vehicles"
                  :clearable="true" :multiselect="true"
                )
          b-table(
            responsive fixed hover :items="exercisesheets" :fields="fields"
          )
            template(#cell(date)="data")
              span {{ formate_date(data.item.date) }}
            template(#cell(details)="row")
              b-button(size="sm" @click="row.toggleDetails" class="mr-2")
                | Dettagli
              b-button(
                size="sm" class="mr-2" variant="light"
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" class="mr-2" variant="info" @click="handlePdf(row.item.id)"
              )
                b-icon-file-earmark-fill
              b-button(
                size="sm" variant="danger" @click="handleDelete(row.index)"
              )
                b-icon-trash
            template(#row-details="row")
              b-card#details
                h3 Capo Squadra
                b-table(
                  striped :items="[row.item.foreman]"
                  :fields="fields_employees"
                )
                h3 Dipendenti
                b-table(
                  striped :items="row.item.employees"
                  :fields="fields_employees"
                )
                // Visualizza la tabella relativa ai veicoli o alle autobotti in base al tipo di foglio
                template(v-if="row.item.sheet_type === 'req'")
                  h3 Automezzi
                  b-table(
                    striped :items="row.item.vehicles" :fields="fields_vehicles"
                  )
                template(v-else-if="row.item.sheet_type === 'atb'")
                  h3 Autobotti
                  b-table(
                    striped :items="row.item.tankers" :fields="fields_tankers"
                  )
                template(v-if="row.item.sheet_type === 'req'")
                  h3 Richieste d'intervento
                  b-table(
                    striped :items="getUnique(row.item)" :fields="fields_operations"
                  )
                    template(#cell(created)="data")
                      span {{ formate_date(data.item.created) }}
                    template(#cell(due)="data")
                      span(v-if="data.item.intervention_ending_date || data.item.due")
                        | {{ data.item.intervention_ending_date ? formate_date(data.item.intervention_ending_date) : formate_date(data.item.due) }}
                    template(#cell(status)="data")
                      el-select(
                        style="width:100px !important"
                        name="status"
                        v-model="data.item.status"
                        @change="editOp(data.item)"
                      )
                        el-option(
                          v-for="item in requests_status"
                          :key="item.value" :label="item.text" :value="item.value"
                        )
                    template(#cell(details)="row")
                      b-button(
                          style="float:left" 
                          :href="`/operations/${row.item.id}/edit`"
                      ) Gestisci
                template(v-else-if="row.item.sheet_type === 'atb'")
                  h3 Interventi ATB
                  b-table(
                    striped :items="getUnique(row.item)" :fields="fields_atb_operations"
                  )
                    template(#cell(created)="data")
                      span {{ formate_date(data.item.created) }}
                    template(#cell(due)="data")
                      span(v-if="data.item.intervention_ending_date || data.item.due")
                        | {{ data.item.intervention_ending_date ? formate_date(data.item.intervention_ending_date) : formate_date(data.item.due) }}
                    template(#cell(status)="data")
                      el-select(
                        style="width:100px !important"
                        name="status"
                        v-model="data.item.status"
                        @change="onStatusChange(data.item, $event)"
                      )
                        el-option(
                          v-for="option in atb_status"
                          :key="option.value" :label="option.text" :value="option.value"
                        )
                    template(#cell(details)="row")
                      b-button(
                          style="float:left" 
                          :href="`/atb-interventions/${row.item.id}/edit`"
                      ) Gestisci

          #pagination
            b-pagination(
              v-model="currentPage" :total-rows="count" per-page="20"
              first-number last-number @change="changePag($event)"
            )
</template>

<script>
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";
import HeaderWhite from "@/components/header-white.vue";
// import { is } from "core-js/core/object";

export default {
  name: "ExerciseSheets",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput,
  },
  data() {
    return {
      isEditing: false,
      exercisesheets: [],
      atb_op: [],
      req_op: [],
      form: {
        sheet_type: "req",
      },
      temp_form: {
        index: null,
        data: {},
      },
      foreman: [],
      vehicles: [],
      tankers: [],
      employees: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "date", label: "Data" },
        { key: "note", label: "Note" },
        { key: "details", label: "" },
      ],
      fields_foreman: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nome" },
      ],
      fields_employees: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nome" },
      ],
      fields_vehicles: [
        { key: "id", label: "ID" },
        { key: "license_plate", label: "Targa" },
        { key: "brand", label: "Marchio" },
        { key: "model", label: "Modello" },
        { key: "frame", label: "Telaio" },
        { key: "details", label: "" },
      ],
      fields_tankers: [
        { key: "id", label: "ID" },
        { key: "plate", label: "Targa" },
        { key: "atb_type", label: "Tipologia ATB" },
        { key: "tank_maker", label: "Fabbricante Cisterna" },
        { key: "tank_year", label: "Anno Cisterna" },
        { key: "is_tped", label: "T-Ped" },
        { key: "details", label: "" },
      ],
      fields_operations: [
        { key: "id", label: "ID" },
        {
          key: "created",
          label: "Creazione",
        },
        {
          key: "type",
          label: "Tipo",
        },
        { key: "due", label: "Scadenza" },

        { key: "store", label: "PV" },
        { key: "status", label: "Stato" },
        { key: "details", label: "" },
      ],
      fields_atb_operations: [
        { key: "id", label: "ID" },
        { key: "employees", label: "ID Dipendente" },
        { key: "intervention_date", label: "Data Inizio" },
        { key: "intervention_ending_date", label: "Data Fine" },
        { key: "status", label: "Stato" },
        { key: "details", label: "" },
      ],
      rules: {
        date: [{ required: true, message: "La data è obbligatoria" }],
      },
      requests_status: [
        { value: "OP", text: "Aperta" },
        { value: "WA", text: "In attesa" },
        { value: "CL", text: "Chiusa" },
        { value: "CA", text: "Annullata" },
      ],
      atb_status: [
        { value: "open", text: "Aperto" },
        { value: "closed", text: "Chiuso" },
      ],
      filters: {},
      count: 0,
      currentPage: 1,
      pageNum: 1,
    };
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true,
    },
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    const query = this.$route.query;
    this.pageNum = parseInt(this.$route.query["p"]) || 1;

    if (query["id"]) this.filters.id = query["id"];
    if (query["date"]) this.filters.date = query["date"];
    if (query["note"]) this.filters.note = query["note"];
    if (query["employees"])
      this.filters.employees = query["employees"]
        .split(",")
        .map((x) => Number(x));
    if (query["vehicles"])
      this.filters.vehicles = query["vehicles"]
        .split(",")
        .map((x) => Number(x));
    if (query["tankers"])
      this.filters.tankers = query["tankers"].split(",").map((x) => Number(x));

    this.getData(true);
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);

    fetch(`${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      for (const vehicle of data.results) {
        this.vehicles.push({
          text: vehicle.license_plate,
          value: vehicle.id,
        });
      }
    });

    // Fetch per le autobotti (Tankers)
    fetch(`${process.env.VUE_APP_BACKEND_URL}/tankers/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      for (const tanker of data.results) {
        this.tankers.push({
          text: tanker.plate, // oppure altra proprietà rappresentativa
          value: tanker.id,
        });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      for (const employee of data.results) {
        this.employees.push({
          text: employee.name,
          value: employee.id,
        });
      }
    });
  },
  methods: {
    filterBy() {
      if (this.filters.date) {
        this.filters.date = this.$moment(this.filters.date).format(
          "YYYY-MM-DD"
        );
      } else {
        delete this.filters.date;
      }

      for (const field of Object.keys(this.filters)) {
        if (!this.filters[field].length) {
          delete this.filters[field];
        }
      }
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        let body_no_json = { ...this.filters };

        if (body_no_json.employees) {
          body_no_json.employees = body_no_json.employees.join(",");
          if (body_no_json.employees.length == 0) delete body_no_json.employees;
        }

        if (body_no_json.vehicles) {
          body_no_json.vehicles = body_no_json.vehicles.join(",");
          if (body_no_json.vehicles.length == 0) delete body_no_json.vehicles;
        }

        body = JSON.stringify(body_no_json);
      }
      route += "?limit=20&offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
        body,
      }).then(async (response) => {
        const data = await response.json();
        /* $1 */
        const modifiedExercisesheets = data.results.map((es) => ({
          ...es,
          tankers:
            es.tankers?.map((tanker) => ({
              ...tanker,
              is_tped: tanker.is_tped === false ? "No" : "Si",
            })) || [],
        }));
        this.exercisesheets = modifiedExercisesheets;
        this.atb_op = data.results.map((x) => x.atb_operations);
        // /* $1 */
        this.req_op = data.results.map((x) => x.operations);
        // /* $1 */
        // /* $1 */
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path + "?_=1";
      if (num > 1) url += "&p=" + num;

      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] && this.filters[field].length) {
          url += "&" + field + "=" + this.filters[field];
        }
      }

      window.location.href = url;
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD");
      return _date.format("DD/MM/YYYY");
    },
    handleNew() {
      this.isEditing = false;
      this.temp_form = { index: null, data: {} };
      this.$bvModal.show("modalform");
    },
    handleEdit(data, index) {
      this.isEditing = true;
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
      this.form.employees = data.employees.map((x) => x.id);
      this.form.employees.unshift(data.foreman.id);
      if (data.sheet_type === "req") {
        this.form.vehicles = data.vehicles.map((x) => x.id);
      } else if (data.sheet_type === "atb") {
        this.form.tankers = data.tankers.map((x) => x.id);
      }
      this.$bvModal.show("modalform");
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(
          this.exercisesheets,
          this.temp_form.index,
          this.temp_form.data
        );
        this.temp_form = {
          index: null,
          data: {},
        };
      }
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.date = this.$moment(
            this.form.date,
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ).format("YYYY-MM-DD");
          let path = "";
          if (this.form.id) {
            path = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${this.form.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/`;
          }
          const action = {
            method: this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };

          this.$refs.headerwhite.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.form),
          })
            .then(async (r) => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast("Foglio d'esercizio salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true,
                });
                const data = await r.json();

                // get data from employees and vehicles/vehicles endpoints
                const employees = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/employees/?limit=1000`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  const data = await res.json();
                  return data.results;
                });

                const vehicles = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=1000`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  const data = await res.json();
                  return data.results;
                });

                const tankers = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/tankers/?limit=1000`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  const data = await res.json();
                  return data.results;
                });

                data.employees = employees.filter(
                  (x) => data.employees.indexOf(x.id) > -1
                );
                data.vehicles = vehicles.filter(
                  (x) => data.vehicles.indexOf(x.id) > -1
                );

                data.tankers = tankers.filter(
                  (x) => data.tankers.indexOf(x.id) > -1
                );

                if (this.form.id) {
                  this.$set(this.exercisesheets, this.temp_form.index, data);
                  this.temp_form = {
                    index: null,
                    data: {},
                  };
                } else {
                  this.exercisesheets.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true,
                });
              }
              window.setTimeout(
                () => (window.location.href = "/exercise-sheets"),
                800
              );
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true,
              });
              window.setTimeout(
                () => (window.location.href = "/exercise-sheets"),
                800
              );
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    async handlePdf(id) {
      this.$refs.headerwhite.loading = true;
      const route = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${id}`;
      fetch(`${route}/pdf/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
        .then(async (r) => ({
          filename: `exercisesheets-${id}.pdf`,
          blob: await r.blob(),
          headers: await r.headers,
        }))
        .then((obj) => {
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore nella generazione del PDF",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.$refs.headerwhite.loading = false;
        });
    },
    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets`;
      fetch(`${route}/${this.exercisesheets[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
        .then(() => {
          this.$bvToast.toast("Foglio esercizio eliminato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true,
          });
          this.exercisesheets.splice(index, 1);
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true,
          });
        });
    },
    getUnique(data) {
      if (data.sheet_type === "req") {
        const new_array = data.operations.map((x) => ({
          ...x.request,
          sheet_type: "req", // Aggiungi sheet_type
        }));
        const array_set = [...new Set(new_array.map(JSON.stringify))];
        // /* $1 */
        return array_set.map((x) => JSON.parse(x));
      } else {
        const new_array = data.atb_operations.map((x) => ({
          ...x.atb_request,
          sheet_type: "atb", // Aggiungi sheet_type
        }));
        const array_set = [...new Set(new_array.map(JSON.stringify))];
        // /* $1 */
        return array_set.map((x) => JSON.parse(x));
      }
    },
    onStatusChange(item, newStatus) {
      // mostriamo un toast informativo;
      // la gestione vera dell'intervento avviene dalla pagina "Gestisci"
      this.$bvToast.toast(
        "Per modificare, utilizza la pagina di gestione, cliccando il pulsante 'GESTISCI'",
        {
          title: "Info",
          variant: "info",
          solid: true,
        }
      );
    },
    editOp(item) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${item.id}/`;
      fetch(route, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
        method: "PATCH",
        body: JSON.stringify(item),
      })
        .then(async (r) => {
          if (r.status == 200) {
            this.$bvToast.toast("Richiesta salvata con successo", {
              title: "Modifica effettuata",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(JSON.stringify(await r.json()), {
              title: "Errore nel salvataggio",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>
