<template lang="html">
  <main>
    <HeaderWhite ref="headerwhite"></HeaderWhite>
    <b-container id="main-container">
      <b-modal
        id="modalpdf"
        @hidden="clear_formpdf"
        hide-footer
        @close="clear_formpdf"
      >
        <el-form ref="formpdf" :model="formpdf">
          <span class="asterisk"
            >I campi seguiti da <code>*</code> sono obbligatori</span
          >
          <FormInput
            :options="atbcustomers"
            label="Cliente"
            :model="formpdf.atbcustomer"
            v-model="formpdf.atbcustomer"
            name="atbcustomer"
          ></FormInput>
          <FormInput
            label="Targa"
            :model="formpdf.plate"
            v-model="formpdf.plate"
            name="plate"
          ></FormInput>
          <FormInput
            label="Tipologia ATB"
            :model="formpdf.atb_type"
            v-model="formpdf.atb_type"
            name="atb_type"
          ></FormInput>
          <FormInput
            :options="[
              { text: 'Triennale', value: 'T' },
              { text: 'Esennale', value: 'E' },
            ]"
            label="Tipologia"
            :model="formpdf.type_expiry"
            v-model="formpdf.type_expiry"
            name="type_expiry"
          ></FormInput>
          <b-button type="button" variant="primary" @click="onSubmitPdf"
            >Visualizza</b-button
          >
        </el-form>
      </b-modal>

      <b-modal
        size="xl"
        id="modalform"
        @hidden="form = {}"
        hide-footer
        @close="handleClose"
        @hide="handleClose"
      >
        <el-form
          :rules="rules"
          label-width="120px"
          ref="form"
          :model="form"
          class="modal-form"
        >
          <span class="asterisk">
            I campi seguiti da <code>*</code> sono obbligatori
          </span>

          <h4>NUOVO</h4>
          <hr />

          <b-row class="row">
            <b-col md="4" sm="12">
              <FormInput
                :options="atbcustomers"
                label="Cliente"
                :model="form.atbcustomer"
                v-model="form.atbcustomer"
                name="atbcustomer"
              ></FormInput>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group label="T-Ped">
                <b-form-checkbox
                  v-model="form.is_tped"
                  name="is_tped"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="12">
              <div class="d-flex align-items-center" id="file-upload-wrapper">
                <span class="mr-2 file-label">Documento</span>
                <!-- Se esiste già un file, lo mostriamo -->
                <div v-if="existing_upload_file" class="mb-2">
                  <a
                    :href="existing_upload_file"
                    target="_blank"
                    class="btn btn-link"
                  >
                    Visualizza file
                  </a>
                </div>
                <!-- Il file input resta sempre presente per selezionare un nuovo file -->
                <b-form-file
                  v-model="form.upload_file"
                  name="file"
                  accept=".pdf, .doc, .docx, .txt"
                  placeholder="Seleziona file..."
                  drop-placeholder="Trascina qui il file..."
                  @change="onFileChange"
                  id="file-upload"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-15 lh-20">
            <b-col md="4" sm="12">
              <FormInput
                label="Targa"
                :model="form.plate"
                v-model="form.plate"
                name="plate"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Veicolo"
                :model="form.vehicle"
                v-model="form.vehicle"
                name="vehicle"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Anno Veicolo"
                :model="form.vehicle_year"
                v-model="form.vehicle_year"
                name="vehicle_year"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row class="mb-15 lh-20">
            <b-col>
              <FormInput
                label="Numero Telaio"
                :model="form.chassis_number"
                v-model="form.chassis_number"
                name="chassis_number"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Tipologia ATB"
                :model="form.atb_type"
                v-model="form.atb_type"
                name="atb_type"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Fabbricante Cisterna"
                :model="form.tank_maker"
                v-model="form.tank_maker"
                name="tank_maker"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row class="mb-15 lh-20">
            <b-col md="4" sm="12">
              <FormInput
                label="Capacità totale serbatoio"
                :model="form.storage_capacity"
                v-model="form.storage_capacity"
                name="storage_capacity"
              ></FormInput>
            </b-col>
            <b-col md="3" sm="12">
              <FormInput
                label="Anno Cisterna"
                :model="form.tank_year"
                v-model="form.tank_year"
                name="tank_year"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row class="mb-15 lh-20">
            <b-col md="4" sm="12">
              <FormInput
                label="Matricola Contalitri"
                :model="form.volume_counter_serial"
                v-model="form.volume_counter_serial"
                name="volume_counter_serial"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Matricola testata"
                :model="form.cylinder_head_serial"
                v-model="form.cylinder_head_serial"
                name="cylinder_head_serial"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Anno Contalitri"
                :model="form.flow_meter_year"
                v-model="form.flow_meter_year"
                name="flow_meter_year"
              ></FormInput>
            </b-col>
          </b-row>

          <h4>Scadenze</h4>
          <hr />

          <b-row class="lh-20">
            <b-col md="4" sm="12">
              <FormInput
                :options="[
                  { text: 'Triennale', value: 'T' },
                  { text: 'Esennale', value: 'E' },
                ]"
                label="Tipologia"
                :model="form.type_expiry"
                v-model="form.type_expiry"
                name="type_expiry"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Scadenza"
                :model="form.year_expiry"
                v-model="form.year_expiry"
                name="year_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row class="lh-20">
            <b-col md="5" sm="12">
              <FormInput
                label="Misuratore GPL"
                :model="form.lpg_meter_expiry"
                v-model="form.lpg_meter_expiry"
                name="lpg_meter_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Misuratore Benzina"
                :model="form.gasoline_meter_expiry"
                v-model="form.gasoline_meter_expiry"
                name="gasoline_meter_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Misuratore Gasolio"
                :model="form.diesel_meter_expiry"
                v-model="form.diesel_meter_expiry"
                name="diesel_meter_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row class="lh-20">
            <b-col md="4" sm="12">
              <FormInput
                label="Manichetta"
                :model="form.hose_expiry"
                v-model="form.hose_expiry"
                name="hose_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Anno manichetta"
                :model="form.hose_year"
                v-model="form.hose_year"
                name="hose_year"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4" sm="12">
              <FormInput
                label="Stradale"
                :model="form.road_expiry"
                v-model="form.road_expiry"
                name="road_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="DDTT 306"
                :model="form.ddtt306_expiry"
                v-model="form.ddtt306_expiry"
                name="ddtt306_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4" sm="12">
              <FormInput
                label="Tachigrafo"
                :model="form.tachograph_expiry"
                v-model="form.tachograph_expiry"
                name="tachograph_expiry"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <FormInput
                label="Note"
                :text="true"
                :model="form.note"
                v-model="form.note"
                name="note"
              ></FormInput>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button type="button" variant="primary" @click="onSubmit">
                Salva
              </b-button>
            </b-col>
          </b-row>
        </el-form>
      </b-modal>
      <div>
        <AdminSidebar nav="tankers"></AdminSidebar>
        <aside class="_body">
          <b-nav id="header">
            <div class="d-flex">
              <b-button v-b-modal.modalform variant="primary"
                >Nuovo ATB</b-button
              >
              <b-button
                variant="secondary"
                class="report-pdf"
                v-b-modal.modalpdf
                >Genera PDF</b-button
              >
              <b-button
                class="report-pdf"
                variant="outline-secondary"
                @click="handleResetFilters"
                >Cancella filtri</b-button
              >
            </div>
          </b-nav>
          <div class="filter-section">
            <b-button
              class="mt-3"
              v-b-toggle.collapse-1
              variant="outline-primary"
              size="md"
              >Filtra risultati</b-button
            >
            <b-collapse id="collapse-1" class="mt-2">
              <el-form id="filters">
                <b-row>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Cliente"
                      name="atbcustomer"
                      :model="filters.atbcustomer"
                      v-model="filters.atbcustomer"
                      :options="atbcustomers"
                    />
                  </b-col>

                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Tipologia ATB"
                      :model="filters.atb_type"
                      v-model="filters.atb_type"
                      name="atb_type"
                    ></FormInput>
                  </b-col>

                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Targa"
                      :model="filters.plate"
                      v-model="filters.plate"
                      name="plate"
                    ></FormInput>
                  </b-col>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Anno Veicolo"
                      :model="filters.vehicle_year"
                      v-model="filters.vehicle_year"
                      name="vehicle_year"
                    ></FormInput>
                  </b-col>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Numero Telaio"
                      :model="filters.chassis_number"
                      v-model="filters.chassis_number"
                      name="chassis_number"
                    ></FormInput>
                  </b-col>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Fabbricante Cisterna"
                      :model="filters.tank_maker"
                      v-model="filters.tank_maker"
                      name="tank_maker"
                    ></FormInput>
                  </b-col>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Capacità serbatoio"
                      :model="filters.storage_capacity"
                      v-model="filters.storage_capacity"
                      name="storage_capacity"
                    ></FormInput>
                  </b-col>
                  <b-col lg="3" sm="12">
                    <FormInput
                      label="Anno Cisterna"
                      :model="filters.tank_year"
                      v-model="filters.tank_year"
                      name="tank_year"
                    ></FormInput>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-matricola
                      class="clickable-header-matricola"
                    >
                      Filtri Matricola
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>
                <b-collapse id="collapse-matricola">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="Matricola Contalitri"
                        :model="filters.volume_counter_serial"
                        v-model="filters.volume_counter_serial"
                        name="volume_counter_serial"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="Matricola testata"
                        :model="filters.cylinder_head_serial"
                        v-model="filters.cylinder_head_serial"
                        name="cylinder_head_serial"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="Matricola Cisterna"
                        :model="filters.tank_serial_number"
                        v-model="filters.tank_serial_number"
                        name="tank_serial_number"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </b-collapse>

                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-scadenze
                      class="clickable-header-scadenze"
                    >
                      Filtri Scadenze
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>

                <b-collapse id="collapse-scadenze">
                  <b-row class="g-3 mb-3">
                    <b-col lg="2" sm="6">
                      <FormInput
                        label="Esennale Inizio"
                        :model="filters.six_year_expiry_start"
                        v-model="filters.six_year_expiry_start"
                        name="six_year_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="2" sm="6">
                      <FormInput
                        label="Esennale Fine"
                        :model="filters.six_year_expiry_end"
                        v-model="filters.six_year_expiry_end"
                        name="six_year_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="2" sm="6">
                      <FormInput
                        label="Triennale Inizio"
                        :model="filters.three_year_expiry_start"
                        v-model="filters.three_year_expiry_start"
                        name="three_year_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="2" sm="6">
                      <FormInput
                        label="Triennale Fine"
                        :model="filters.three_year_expiry_end"
                        v-model="filters.three_year_expiry_end"
                        name="three_year_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>
                  </b-row>
                </b-collapse>
                <!-- Intestazione cliccabile -->
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-misuratori
                      class="clickable-header-misuratori"
                    >
                      Filtri Misuratori
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>

                <!-- Contenuto collassabile -->
                <b-collapse id="collapse-misuratori">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore GPL Inizio"
                        :model="filters.lpg_meter_expiry_start"
                        v-model="filters.lpg_meter_expiry_start"
                        name="lpg_meter_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore GPL Fine"
                        :model="filters.lpg_meter_expiry_end"
                        v-model="filters.lpg_meter_expiry_end"
                        name="lpg_meter_expiry"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore Benzina Inizio"
                        :model="filters.gasoline_meter_expiry_start"
                        v-model="filters.gasoline_meter_expiry_start"
                        name="gasoline_meter_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore Benzina Fine"
                        :model="filters.gasoline_meter_expiry_end"
                        v-model="filters.gasoline_meter_expiry_end"
                        name="gasoline_meter_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore Gasolio Inizio"
                        :model="filters.diesel_meter_expiry_start"
                        v-model="filters.diesel_meter_expiry_start"
                        name="diesel_meter_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>

                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Misuratore Gasolio Fine"
                        :model="filters.diesel_meter_expiry_end"
                        v-model="filters.diesel_meter_expiry_end"
                        name="diesel_meter_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona data"
                      />
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- Manichetta -->
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-manichetta
                      class="clickable-header-manichetta"
                    >
                      Filtri Manichetta
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>
                <b-collapse id="collapse-manichetta">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Manichetta Inizio"
                        :model="filters.hose_expiry_start"
                        v-model="filters.hose_expiry_start"
                        name="hose_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Manichetta Fine"
                        :model="filters.hose_expiry_end"
                        v-model="filters.hose_expiry_end"
                        name="hose_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3">
                      <FormInput
                        label="Anno manichetta"
                        :model="filters.hose_year"
                        v-model="filters.hose_year"
                        name="hose_year"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- Stradale -->
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-stradale
                      class="clickable-header-stradale"
                    >
                      Filtri Stradale
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>
                <b-collapse id="collapse-stradale">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Stradale Inizio"
                        :model="filters.road_expiry_start"
                        v-model="filters.road_expiry_start"
                        name="road_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="6">
                      <FormInput
                        label="Stradale Fine"
                        :model="filters.road_expiry_end"
                        v-model="filters.road_expiry_end"
                        name="road_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- DDTT -->
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5 v-b-toggle.collapse-ddtt class="clickable-header-ddtt">
                      Filtri DDTT 306
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>
                <b-collapse id="collapse-ddtt">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="DDTT 306 Inizio"
                        :model="filters.ddtt306_expiry_start"
                        v-model="filters.ddtt306_expiry_start"
                        name="ddtt306_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="DDTT 306 Fine"
                        :model="filters.ddtt306_expiry_end"
                        v-model="filters.ddtt306_expiry_end"
                        name="ddtt306_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- Tachigrafo -->
                <b-row class="align-items-center mb-2">
                  <b-col>
                    <h5
                      v-b-toggle.collapse-tachigrafo
                      class="clickable-header-tachigrafo"
                    >
                      Filtri Tachigrafo
                      <b-icon icon="chevron-down" class="when-open"></b-icon>
                      <b-icon icon="chevron-up" class="when-closed"></b-icon>
                    </h5>
                  </b-col>
                </b-row>
                <b-collapse id="collapse-tachigrafo">
                  <b-row class="g-3 mb-3">
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="Tachigrafo Inizio"
                        :model="filters.tachograph_expiry_start"
                        v-model="filters.tachograph_expiry_start"
                        name="tachograph_expiry_start"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <FormInput
                        label="Tachigrafo Fine"
                        :model="filters.tachograph_expiry_end"
                        v-model="filters.tachograph_expiry_end"
                        name="tachograph_expiry_end"
                        :datepicker="true"
                        :clearable="true"
                        placeholder="Seleziona una data"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </b-collapse>
              </el-form>
            </b-collapse>
          </div>
          <b-table
            responsive
            fixed
            hover
            :items="tankers"
            :fields="fields"
            class="mt-3"
          >
            <template #cell(upload_file)="data">
              <span v-if="data.item.upload_file">
                <a :href="data.item.upload_file" target="_blank"
                  >Visualizza File</a
                >
              </span>
              <span v-else>No</span>
            </template>
            <template #cell(is_tped)="data">
              <span v-if="data.item.is_tped">Sì</span>
              <span v-else>No</span>
            </template>
            <template #cell(details)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? "Nascondi" : "Mostra" }} dettagli
              </b-button>
              <b-button
                size="sm"
                class="mr-2"
                variant="light"
                v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              >
                <b-icon-pencil></b-icon-pencil>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="handleDelete(row.index)"
              >
                <b-icon-trash></b-icon-trash>
              </b-button>
            </template>
            <template #row-details="row">
              <b-card id="details">
                <b-row>
                  <b-col cols="6" lg="4">
                    <b>Cliente:</b>
                    <span>{{ row.item.atbcustomer.corporate_name }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Targa:</b> <span>{{ row.item.plate }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Veicolo:</b> <span>{{ row.item.vehicle }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Anno Veicolo: </b>
                    <span>{{ row.item.vehicle_year }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Numero Telaio: </b>
                    <span>{{ row.item.chassis_number }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>T-Ped: </b> <span>{{ row.item.is_tped }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Tipologia ATB:</b> <span>{{ row.item.atb_type }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Fabbricante cisterna:</b>
                    <span>{{ row.item.tank_maker }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Matricola cisterna:</b>
                    <span>{{ row.item.tank_serial_number }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Capacità totale serbatoio:</b>
                    <span>{{ row.item.storage_capacity }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Anno Serbatoio:</b>
                    <span>{{ row.item.tank_year }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Marca contalitri:</b>
                    <span>{{ row.item.volume_counter_mark }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Matricola contalitri:</b>
                    <span>{{ row.item.volume_counter_serial }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Matricola Testata:</b>
                    <span>{{ row.item.cylinder_head_serial }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Anno contalitri:</b>
                    <span>{{ row.item.flow_meter_year }}</span>
                  </b-col>

                  <b-col cols="6" lg="4">
                    <b>Tipologia scadenza:</b>
                    <span>{{ row.item.type_expiry }}</span>
                  </b-col>

                  <b-col cols="6" lg="4">
                    <b>Scadenza:</b>
                    <span>{{ row.item.year_expiry }}</span>
                  </b-col>

                  <b-col cols="6" lg="4">
                    <b>Scadenza Misuratore GPL:</b>
                    <span>{{ row.item.lpg_meter_expiry }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Scadenza Misuratore Benzina:</b>
                    <span>{{ row.item.gasoline_meter_expiry }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Scadenza Misuratore Gasolio:</b>
                    <span>{{ row.item.diesel_meter_expiry }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Scadenza Manichetta:</b>
                    <span>{{ row.item.hose_expiry }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Anno Manichetta:</b>
                    <span>{{ row.item.hose_year }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Scadenza Stradale:</b>
                    <span>{{ row.item.road_expiry }}</span>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <b>Scadenza DDTT306:</b>
                    <span>{{ row.item.ddtt306_expiry }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>Scadenza Tachigrafo:</b>
                    <span>{{ row.item.tachograph_expiry }}</span>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b>Note:</b> <span>{{ row.item.note }}</span>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
          <div id="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              per-page="20"
              first-number
              last-number
              @change="changePag($event)"
            ></b-pagination>
          </div>
        </aside>
      </div>
    </b-container>
  </main>
</template>

<script>
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";
import HeaderWhite from "@/components/header-white.vue";
export default {
  name: "Tanker",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput,
  },
  data() {
    return {
      pvVerify: "",
      tankers: [],
      atbcustomers: [],
      existing_upload_file: "",
      form: {
        _e: false,
        closing_days: "",
        note: "",
        upload_file: null,
      },
      formpdf: {},
      temp_form: {
        index: null,
        data: {
          _e: false,
          closing_days: "",
          note: "",
          upload_file: null,
        },
      },
      fields: [
        {
          key: "atbcustomer.corporate_name",
          label: "Azienda Cliente",
          sortable: true,
        },
        { key: "plate", label: "Targa", sortable: true },
        { key: "atb_type", label: "Tipologia ATB", sortable: true },
        { key: "tank_maker", label: "Fabbricante Cisterna", sortable: true },
        { key: "tank_year", label: "Anno Cisterna", sortable: true },
        { key: "upload_file", label: "Documenti" },
        { key: "is_tped", label: "T-Ped", sortable: true },
        { key: "details", label: "" },
      ],
      regions: [],
      provinces: [],
      rules: {
        atbcustomer: [
          { required: true, message: "Il cliente ATB è obbligatorio" },
        ],
        store: [
          {
            required: true,
            message: "Il negozio può essere lungo fino a 10 caratteri",
          },
        ],
        plate: [
          {
            required: true,
            max: 16,
            message: "La targa può essere lunga fino a 16 caratteri",
          },
        ],
        vehicle: [
          {
            max: 25,
            message: "Il veicolo può essere lungo fino a 25 caratteri",
          },
        ],
        chassis_number: [
          {
            max: 25,
            message: "Il numero di telaio può essere lungo fino a 25 caratteri",
          },
        ],
        vehicle_year: [
          {
            max: 30,
            message: "L'anno del veicolo può essere lungo fino a 30 caratteri",
          },
        ],
        atb_type: [
          {
            max: 30,
            message: "Il tipo ATB può essere lungo fino a 30 caratteri",
          },
        ],
        tank_maker: [
          {
            max: 20,
            message:
              "Il produttore del serbatoio può essere lungo fino a 20 caratteri",
          },
        ],
        storage_capacity: [
          {
            max: 15,
            message:
              "La capacità di stoccaggio può essere lunga fino a 15 caratteri",
          },
        ],
        tank_serial_number: [
          {
            max: 30,
            message:
              "Il numero di serie del serbatoio può essere lungo fino a 30 caratteri",
          },
        ],
        tank_year: [
          {
            max: 30,
            message:
              "L'anno del serbatoio può essere lungo fino a 30 caratteri",
          },
        ],
        volume_counter_mark: [
          {
            max: 30,
            message:
              "Il marchio del contatore di volume può essere lungo fino a 30 caratteri",
          },
        ],
        volume_counter_serial: [
          {
            max: 90,
            message:
              "Il numero di serie del contatore di volume può essere lungo fino a 90 caratteri",
          },
        ],
        cylinder_head_serial: [
          {
            max: 30,
            message:
              "Il numero di serie della testata cilindri può essere lungo fino a 30 caratteri",
          },
        ],
        flow_meter_year: [
          {
            message:
              "L'anno del misuratore di flusso può essere lungo fino a 30 caratteri",
          },
        ],
        note: [
          {
            max: 200,
            message: "Le note possono essere lunghe fino a 200 caratteri",
          },
        ],
      },
      filters: {},
      customerFilter: null,
      count: 0,
      currentPage: 1,
      pageNum: 1,
    };
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true,
    },
    customerFilter: function () {
      this.filterBy();
    },
  },
  mounted() {
    /* $1 */
    this.access_token = this.$cookies.get("access_token");
    this.pageNum = parseInt(this.$route.query["p"]) || 1;
    const query = this.$route.query;
    if (query["pv"]) this.filters.pv = query["pv"];
    if (query["pbl"]) this.filters.pbl = query["pbl"];
    if (query["atbcustomer"]) {
      this.customerFilter = query["atbcustomer"];
      this.filters.customer = query["atbcustomer"];
    }
    if (query["street"]) this.filters.street = query["street"];
    if (query["province"]) this.filters.province = query["province"];
    if (query["region"]) this.filters.region = query["region"];
    this.getData();
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);
    fetch(`${process.env.VUE_APP_BACKEND_URL}/atb-customers/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      for (const atbcustomer of data.results) {
        this.atbcustomers.push({
          text: atbcustomer.corporate_name,
          value: atbcustomer.id,
        });
      }
    });
    fetch("/regions.json").then(async (res) => {
      for (const i of await res.json()) {
        this.regions.push({ text: i, value: i });
      }
    });
    fetch("/provinces.json").then(async (res) => {
      for (const i of await res.json()) {
        this.provinces.push({ text: i.text, value: i.value });
      }
    });
  },
  methods: {
    onFileChange(event) {
      this.form.upload_file = event.target.files[0];
    },

    clear_formpdf() {
      this.formpdf = {};
    },
    async onSubmitPdf() {
      this.$refs["formpdf"].validate(async (valid) => {
        if (valid) {
          this.$refs.headerwhite.loading = true;
          const url = `${process.env.VUE_APP_BACKEND_URL}/tankers/pdf/?limit=1000`;
          await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
            body: JSON.stringify(this.formpdf),
          })
            .then(async (r) => ({
              filename: `report_autobotti_${new Date().toLocaleDateString()}.pdf`,
              blob: await r.blob(),
              headers: await r.headers,
            }))
            .then((obj) => {
              const newBlob = new Blob([obj.blob], { type: "application/pdf" });
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement("a");
                link.href = objUrl;
                link.download = obj.filename;
                link.click();

                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);

                this.$bvToast.toast("File Generato", {
                  title: "Il file PDF è stato generato correttamente!",
                  variant: "success",
                  solid: true,
                });
              }
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Errore nella generazione del PDF",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => {
              this.$refs.headerwhite.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    filterBy() {
      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] === "" || this.filters[field] === null) {
          delete this.filters[field];
        }
      }
      if (this.customerFilter) {
        this.filters.customer = this.customerFilter;
      }
      this.formatDateFieldsFilters();
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/tankers/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        body = JSON.stringify(this.filters);
      }
      route += "?limit=20&offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
        body,
      }).then(async (response) => {
        const data = await response.json();
        this.tankers = data.results;
        /* $1 */
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path + "?_=1";
      if (num > 1) url += "&p=" + num;
      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] && this.filters[field].length) {
          url += "&" + field + "=" + this.filters[field];
        }
      }
      window.location.href = url;
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD hh:mm:ss");
      return _date.format("DD/MM/YYYY HH:mm");
    },
    handleEdit(data, index) {
      // Converti le stringhe di data in oggetti Date
      const dateFields = [
        "year_expiry",
        "lpg_meter_expiry",
        "gasoline_meter_expiry",
        "diesel_meter_expiry",
        "hose_expiry",
        "road_expiry",
        "ddtt306_expiry",
        "tachograph_expiry",
      ];

      // Clona e converte le date
      const formattedData = {
        ...data,
        atbcustomer: data.atbcustomer.id,
        upload_file: null,
      };

      dateFields.forEach((field) => {
        if (data[field]) {
          formattedData[field] = new Date(data[field]);
        }
      });

      this.id = data.id;
      this.pvVerify = data.pv;
      this.temp_form.index = index;
      this.form = formattedData;
      this.existing_upload_file = data.upload_file;
      this.form._e = true;

      /* $1 */
    },

    handleClose() {
      // Resetta i campi data
      const dateFields = [
        "year_expiry",
        "lpg_meter_expiry",
        "gasoline_meter_expiry",
        "diesel_meter_expiry",
        "hose_expiry",
        "road_expiry",
        "ddtt306_expiry",
        "tachograph_expiry",
      ];

      dateFields.forEach((field) => {
        this.form[field] = null;
      });

      if (this.temp_form.index != null) {
        this.temp_form = {
          index: null,
          data: {
            _e: false,
            closing_days: "",
            note: "",
          },
        };
      }

      // Resetta eventuali file selezionati
      this.form.upload_file = null;
      this.existing_upload_file = null;
    },
    handleResetFilters() {
      this.filters = {};
      this.$bvToast.toast(`Filtri cancellati`, {
        title: "Filtri cancellati con successo!",
        variant: "success",
        solid: true,
      });
    },
    formatDateFieldsForm() {
      const dateFields = [
        "year_expiry",
        "lpg_meter_expiry",
        "gasoline_meter_expiry",
        "diesel_meter_expiry",
        "hose_expiry",
        "road_expiry",
        "ddtt306_expiry",
        "tachograph_expiry",
      ];

      for (const field of dateFields) {
        if (this.form[field] instanceof Date) {
          const formattedDate = this.$moment(this.form[field]).format(
            "YYYY-MM-DD"
          );
          this.$set(this.form, field, formattedDate);
        } else if (this.form[field] === null) {
          // Mantieni esplicitamente null per i campi cancellati
          this.$set(this.form, field, null);
        }
      }
    },
    formatDateFieldsFilters() {
      const dateFields = [
        "year_expiry",
        "lpg_meter_expiry",
        "gasoline_meter_expiry",
        "diesel_meter_expiry",
        "hose_expiry",
        "road_expiry",
        "ddtt306_expiry",
        "tachograph_expiry",
      ].flatMap((field) => [`${field}_start`, `${field}_end`]);

      for (const field of dateFields) {
        if (this.filters[field]) {
          const formattedDate = this.$moment(this.filters[field]).format(
            "YYYY-MM-DD"
          );
          if (this.$moment(this.filters[field]).isValid()) {
            this.$set(this.filters, field, formattedDate);
          }
        }
      }
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.formatDateFieldsForm();
          let path = "";
          if (this.form._e) {
            path = `${process.env.VUE_APP_BACKEND_URL}/tankers/${this.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/tankers/`;
          }
          let dataToSend = { ...this.form };
          delete dataToSend._e; // Rimuovi campi che non sono previsti dal modello

          // Se vuoi inviare solo i campi "non vuoti" o modificati, puoi filtrare ulteriormente:
          for (const key in dataToSend) {
            // Se il valore è undefined o null (o, in base alla logica, anche una stringa vuota) lo rimuovi.
            // vogliamo aggiornare anche un campo in cui l'utente ha cancellato il contenuto; quindi non rimuoviamo le stringhe vuote
            if (dataToSend[key] === undefined) {
              delete dataToSend[key];
            }
          }
          /* $1 */
          const formData = new FormData();

          // Aggiungi tutti i valori (anche null) tranne upload_file vuoto
          for (const key in dataToSend) {
            if (
              key === "upload_file" &&
              (!dataToSend[key] || typeof dataToSend[key] !== "object")
            ) {
              continue;
            }
            formData.append(
              key,
              dataToSend[key] === null ? "" : dataToSend[key]
            ); // Invia null come stringa vuota
          }

          for (let pair of formData.entries()) {
            /* $1 */
          }
          const action = {
            method: this.form._e ? "PATCH" : "POST",
            url: path,
            headers: {
              // "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };

          this.loading = true;
          /* $1 */
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: formData,
            // this.form.upload_file == null
            //   ? JSON.stringify(this.form)
            //   : JSON.stringify(this.form),
          })
            .then(async (r) => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast("Autobotte salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true,
                });
                this.$bvModal.hide("modalform");
                this.form = {};
                this.existing_upload_file = null;
                this.getData();
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },

    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/tankers`;
      fetch(`${route}/${this.tankers[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
        .then(() => {
          this.$bvToast.toast("Autobotte eliminata con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true,
          });
          this.tankers.splice(index, 1);
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true,
          });
        });
      this.getData();
    },

    formatStoreData(store) {
      return `${store.pv} - ${store.city} (${store.province}), ${store.region}`;
    },
  },
};
</script>

<style scoped>
.filter-col {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
}

#filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.filter-section {
  margin-top: 20px;
}
</style>
