<template lang="pug">
  main
    HeaderWhite(ref="headerwhite")
    b-container#main-container
      b-modal(id="modalformex" @hidden="formex={}" hide-footer)
        h2 Aggiungi un nuovo foglio d'esercizio
        el-form(:rules="rulesExercise" label-width="90px" ref="formex" :model="formex")
          span.asterisk 
            | I campi contrassegnati da <code>*</code> sono obbligatori
          FormInput(
            label="Data"
            name="date"
            :model="formex.date"
            v-model="formex.date"
            :datepicker="true"
            :clearable="true"
          )
          FormInput(
            label="Dipendenti"
            name="employees"
            :model="formex.employees"
            v-model="formex.employees"
            :options="employees"
            :multiselect="true"
          )
          FormInput(
            label="Autobotti"
            name="vehicles"
            :model="formex.tankers"
            v-model="formex.tankers"
            :options="platesFE"
            :multiselect="true"
          )
          FormInput(
            label="Note"
            name="note"
            :model="formex.note"
            v-model="formex.note"
            :text="true"
          )
          b-button(variant="primary" @click="onSubmitEx") Salva

      b-modal(
        id="modalform"
        @hidden="form={}"
        hide-footer
        @close="handleClose"
        @hide="handleClose"
      )
        el-form(:rules="rulesop" ref="formop" :model="form")
          span.asterisk 
            | I campi contrassegnati da <code>*</code> sono obbligatori
          b-row
            b-col(md="10" sm="12")
              FormInput(
                label="Foglio d'esercizio"
                name="fe"
                :model="form.fe"
                v-model="form.fe"
                :options="exerciseSheets"
              )
            b-col(md="2" sm="12")
              b-button.add-b-b(
                variant="success"
                block
                v-b-modal.modalformex
              )
                b-icon-plus

          FormInput(
            label="Orario"
            :model="form.hour"
            :timepicker="true"
            v-model="form.hour"
            name="hour"
          )
          FormInput(
            label="Note"
            :text="true"
            :model="form.note"
            v-model="form.note"
            name="note"
          )
          b-button(
            type="button"
            variant="primary"
            @click="onSubmit(1)"
          ) Salva

      div
        AdminSidebar(nav="interventions")
        aside._body
          h3 Dettagli Intervento Autobotte
          el-form(:rules="rulesMain" ref="mainIntervention" :model="mainIntervention")
            span.asterisk 
              | I campi contrassegnati da <code>*</code> sono obbligatori

            b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Nome azienda"
                  name="tanker"
                  :model="mainIntervention.tanker"
                  v-model="mainIntervention.tanker"
                  :options="tankers"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Targa"
                  name="plate"
                  :model="mainIntervention.plate"
                  v-model="mainIntervention.plate"
                  value-field="value"
                  text-field="text"
                  :options="plates"
                  :clearable="true"
                )
            b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Data Inizio Intervento"
                  name="intervention_date"
                  :model="mainIntervention.intervention_date"
                  v-model="mainIntervention.intervention_date"
                  :datepicker="true"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Data Fine Intervento"
                  name="intervention_ending_date"
                  :model="mainIntervention.intervention_ending_date"
                  v-model="mainIntervention.intervention_ending_date"
                  :datepicker="true"
                  :clearable="true"
                )
            b-row
              b-col(sm="12" md="4")
                FormInput(
                  label="Stato"
                  name="status"
                  :model="mainIntervention.status"
                  v-model="mainIntervention.status"
                  :options="requests_status"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Dipendenti"
                  name="employees"
                  :model="mainIntervention.employees"
                  v-model="mainIntervention.employees"
                  :options="employees"
                  :multiselect="true"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="N. Fattura"
                  :model="mainIntervention.invoice_number"
                  v-model="mainIntervention.invoice_number"
                  type="text"
                  :clearable="true"
                )
              b-col(sm="12" md="4")
                FormInput(
                  label="Importo pagato"
                  :model="mainIntervention.amount_paid"
                  v-model="mainIntervention.amount_paid"
                  type="number"
                  :clearable="true"
                )
            b-row
              b-col(cols="12")
                FormInput(
                  label="Descrizione"
                  name="description"
                  :model="mainIntervention.description"
                  v-model="mainIntervention.description"
                  :text="true"
                  rows="3"
                  max-rows="6"
                  :clearable="true"
                )

            b-button(variant="primary" @click="onSubmit(0)") Salva

          hr
          h3 Storico interventi
          b-nav#header
            b-button(variant="primary" v-b-modal.modalform) Nuovo intervento
            #right
              b-input(
                placeholder="Filtra"
                v-model="filter"
                class="mt-2"
              )

          b-table(
            :items="operations"
            :fields="fieldsExercise"
            :filter="filter"
            hover
            responsive
          )
            template(#cell(employees)="data")
              span {{ data.item.employees.map(e => e.name).join(', ') }}
            template(#cell(tankers)="data")
              span {{ data.item.tankers.map(v => v.plate).join(', ') }}
            template(#cell(date)="data")
              span {{ formatDate(data.item.date) }}
            template(#cell(note)="data")
              span {{ data.item.note }}
            template(#cell(actions)="row")
              b-button(
                size="sm"
                class="mr-2"
                variant="light"
                v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm"
                variant="danger"
                @click="handleDelete(row.index)"
              )
                b-icon-trash

          b-button(
            @click="goBack"
            style="margin: 50px auto;"
          ) 
            | Torna indietro
</template>

<script>
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";
import HeaderWhite from "@/components/header-white.vue";

export default {
  name: "InterventionsDetails",
  props: ["id"], // PK dell'AtbIntervention
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput,
  },
  data() {
    return {
      // Intervento principale
      mainIntervention: {},
      // Lista di sub-interventi (operations)
      operations: [],
      // Lista di ExerciseSheet
      exerciseSheets: [],
      // Form per creare un new ExerciseSheet
      formex: {},
      // Form per sub-intervento
      form: {},
      // Filtri
      filter: "",
      // Campi della tabella sub-interventi
      fieldsExercise: [
        { key: "id", label: "ID" },
        { key: "date", label: "Data" },
        { key: "fe.id", label: "FE" },
        { key: "employees", label: "Dipendenti" },
        { key: "tankers", label: "Veicoli" },
        { key: "note", label: "Note" },
        { key: "actions", label: "" },
      ],
      // Regole validazione
      rulesMain: {
        tanker: [{ required: true, message: "Autobotte obbligatoria" }],
        plate: [{ required: true, message: "Targa obbligatoria" }],
        intervention_date: [
          { required: true, message: "Data inizio obbligatoria" },
        ],
        intervention_ending_date: [
          { required: true, message: "Data fine obbligatoria" },
        ],
        status: [{ required: true, message: "Stato obbligatorio" }],
        description: [{ required: true, message: "Descrizione obbligatoria" }],
      },
      rulesop: {
        hour: [{ required: true, message: "L'orario è obbligatorio" }],
        fe: [
          { required: true, message: "Il foglio d'esercizio è obbligatorio" },
        ],
      },
      rulesExercise: {
        date: [{ required: true, message: "La data è obbligatoria" }],
      },

      // Opzioni select
      tankers: [],
      plates: [],
      platesFE: [],
      employees: [],
      vehicles: [],
      requests_status: [
        { value: "open", text: "Aperto" },
        { value: "closed", text: "Chiuso" },
      ],
      // Gestione modale in editing
      temp_form: {
        index: null,
        data: {},
      },
    };
  },
  async mounted() {
    this.access_token = this.$cookies.get("access_token");

    // 1) Carica i dati dell'intervento principale
    let url = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/${this.$props.id}/`;
    const resp = await fetch(url, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    });
    if (resp.ok) {
      this.mainIntervention = await resp.json();
    } else {
      this.$bvToast.toast("Impossibile caricare l'intervento", {
        title: "Errore",
        variant: "danger",
        solid: true,
      });
    }

    // 2) Carica i sub-interventi: es. /api/v1/atb-interventions/<id>/atb-operations/
    let opUrl = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/${this.$props.id}/atb-operations/?limit=100`;
    fetch(opUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.access_token}`,
      },
    })
      .then((r) => r.json())
      .then((data) => {
        // Se la viewset restituisce {results: [...]}:
        this.operations = data.results || data;
      });

    // 3) Carica i fogli d'esercizio
    fetch(`${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/?limit=100`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.access_token}`,
      },
    })
      .then((r) => r.json())
      .then((sheets) => {
        for (const sheet of sheets.results) {
          if (sheet.sheet_type === "atb") {
            this.exerciseSheets.push({
              text: `${sheet.id} - ${sheet.date}`,
              value: sheet.id,
            });
          }
        }
      });
    //  **______________________________________________**
    //  |
    /* |in questo modo verranno visualizzati solo
          i fogli d'esercizio che hanno il sheet_type: "atb",
          e che quindi sono stati creati dalla pagina di interventi
          autobotti tramite submitEx() e che avranno la targa dell'autobotte
          e non del veicolo (distinzione principale per tale divisione del
          tipo del foglio d'esercizio.
          stesso discorso avviene in OperationsDetails.vue ma con valore
          : "req"
          */

    // 4) Carica tankers, plates, employees, vehicles
    fetch(`${process.env.VUE_APP_BACKEND_URL}/tankers/`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((r) => r.json())
      .then((d) => {
        /* $1 */
        this.plates = d.results.map((t) => ({
          text: t.plate,
          value: t.plate,
        }));
        this.platesFE = d.results.map((t) => ({
          text: t.plate,
          value: t.id,
        }));
        this.tankers = d.results.map((t) => ({
          text: t.atbcustomer.corporate_name,
          value: t.id,
        }));
      });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/?limit=1000`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((r) => r.json())
      .then((d) => {
        for (const emp of d.results) {
          this.employees.push({ text: emp.name, value: emp.id });
        }
      });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=1000`, {
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((r) => r.json())
      .then((d) => {
        this.vehicles = d.results.map((v) => ({
          text: v.license_plate,
          value: v.id,
        }));
      });
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("DD/MM/YYYY") : "";
    },

    /* ---------------------------------------
       Salva un ExerciseSheet (modale "formex")
    --------------------------------------- */
    onSubmitEx() {
      this.$refs["formex"].validate((valid) => {
        if (valid) {
          let path = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/`;
          const action = {
            method: "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };
          this.$refs.headerwhite.loading = true;
          this.formex = {
            ...this.formex,
            sheet_type: "atb",
          };
          /* $1 */
          this.formex.date = this.$moment(
            this.formex.date,
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ).format("YYYY-MM-DD");
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.formex),
          })
            .then(async (r) => {
              if (r.status === 201) {
                this.$bvToast.toast(
                  "Foglio d'esercizio inserito con successo",
                  {
                    title: "Modifica effettuata",
                    variant: "success",
                    solid: true,
                  }
                );
                const data = await r.json();

                this.exerciseSheets.push({
                  text: `${data.id} - ${data.date}`,
                  value: data.id,
                });
                this.form.fe = data.id; // Imposta sul form dell'operazione (sub-intervento)
                this.$bvModal.hide("modalformex");
                this.formex = {};
              } else {
                this.$bvToast.toast(`Errore ${r.status}`, {
                  title: "Foglio d'esercizio non inserito",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((e) => {
              this.$bvToast.toast(e.message, {
                title: "Foglio d'esercizio non inserito",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    handleEdit(item, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(item));

      this.form = { ...item };
      // Usa optional chaining e assegna array vuoto se undefined
      this.form.employees = item.employees?.map((e) => e.id) || [];
      this.form.tankers = item.tankers?.map((v) => v.id) || []; // Nota: il campo si chiama tankers non vehicles
      this.form.fe = item.fe?.id;
    },

    handleDelete(index) {
      // Esempio: DELETE /atb-interventions/<id>/atb-operations/<subId>/
      const subId = this.operations[index].id;
      const url = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/${this.$props.id}/atb-operations/${subId}/`;

      fetch(url, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${this.access_token}` },
      })
        .then((r) => {
          if (r.ok) {
            this.$bvToast.toast("Sub-intervento eliminato", {
              title: "Eliminato",
              variant: "success",
              solid: true,
            });
            this.operations.splice(index, 1);
          } else {
            this.$bvToast.toast("Errore eliminazione", {
              title: "Errore",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.message, {
            title: "Errore",
            variant: "danger",
            solid: true,
          });
        });
    },

    /* ---------------------------------------
       Chiudi modale sub-intervento
    --------------------------------------- */
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.operations, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {},
        };
      }
    },

    async get_exercise(id) {
      await fetch(`${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${id}/`, {
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      }).then(async (response) => {
        const data = await response.json();
        this.form.date = data.date;
        if (data.foreman) {
          this.form.employees = [
            data.foreman.id,
            ...data.employees.map((x) => x.id),
          ];
        } else {
          this.form.employees = data.employees.map((x) => x.id);
        }
        this.form.vehicles = data.vehicles.map((x) => x.id); //? verificare se necessario cambiare vehicles in tankers
      });
    },

    onSubmit(operation) {
      // formatto le date prima di inviare il form
      this.mainIntervention.intervention_date = this.$moment(
        this.mainIntervention.intervention_date
      ).format("YYYY-MM-DD");
      this.mainIntervention.intervention_ending_date = this.$moment(
        this.mainIntervention.intervention_ending_date
      ).format("YYYY-MM-DD");

      let _m = operation ? "formop" : "mainIntervention";
      this.$refs[_m].validate(async (valid) => {
        if (valid) {
          let text = "Richiesta d'intervento";
          let path = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/${this.$props.id}/`;
          let payload = this.mainIntervention;
          if (operation) {
            await this.get_exercise(this.form.fe);
            text = "Intervento";
            path += "atb-operations/";
            payload = this.form;
            if (this.form.id) {
              path += this.form.id + "/";
            }
          }

          const action = {
            method: !operation || this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
          };

          this.$refs.headerwhite.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(payload),
          })
            .then(async (r) => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast(text + " salvata con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true,
                });
                const data = await r.json();

                // get data from employees and vehicles endpoints
                const employees = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/employees/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  const data = await res.json();
                  return data.results || [];
                });
                const vehicles = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/vehicles/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  const data = await res.json();
                  return data.results;
                });

                // get data of exercisesheets
                await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${this.form.fe}/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`,
                    },
                  }
                ).then(async (res) => {
                  data.fe = await res.json();
                });

                data.employees = employees.filter(
                  (x) => data.employees.indexOf(x.id) > -1
                );
                data.vehicles = vehicles.filter((x) =>
                  (data.vehicles || []).includes(x.id)
                ); //? verificare se necessario cambiare vehicles in tankers

                if (this.form.id) {
                  this.$set(this.operations, this.temp_form.index, data);
                  this.temp_form = {
                    index: null,
                    data: {},
                  };
                } else {
                  this.operations.push({
                    ...data,
                    employees: data.employees || [],
                    tankers: data.tankers || [], // Assicurati che tankers sia sempre un array
                  });
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((e) => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },

    goBack() {
      this.$router.push("/atb-interventions");
    },
  },
};
</script>
